:root {
    --primary-font: Verdana, Geneva, Tahoma, sans-serif;
    
    --primaray-font-color: #ffffff;
    --secondary-font-color: #ff0e1f;
    --primary-background-color: #000000;

    --link-color-hover: #ff0e1f;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--primary-font);
    color: var(--primaray-font-color);
    background-color: var(--primary-background-color);
}

a {
    color: var(--primaray-font-color);
    transition: ease-out 400ms;
}

a:hover {
    color: var(--link-color-hover);
    transition: ease-in 150ms;
}

img {
    width: 100%;
    height: auto;
}

h1,
h2,
h3 {
    margin: 10px 0px 10px 0px;
}

#root {
    width: 100%;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

section {
    margin: 20px;
}

section.intro {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-transform: uppercase;
    }

    h1 span {
        color: var(--secondary-font-color);
    }
}

section.topic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    img {
        width: 360px;
        height: 360px;
    }
    ul {
        list-style-type: none;
        
        li {
            padding: none;
            line-height: 1.1em;

            a {
                font-size: 11px;
            }
        }
    }
}

section.system {
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

section.socialicons {
    ul {
        display: flex;
        flex-direction: row; 
        list-style-type: none;  
    }

    ul li {
        margin: 6px 10px;
    }
}

@media screen and (min-width: 481px) {
    h1,
    h2,
    h3 {
        margin: 0px 0px 10px 0px;
    }

    section.topic {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    
        img {
            width: 360px;
            height: 360px;
            margin-right: 20px;
            position: sticky;
            top: 0px;
        }
        ul {
            list-style-type: none;
            
            li {
                padding: none;
                line-height: 1.8em;

                a {
                    font-size: 14px;
                }
            }
        }
    }
}